import * as React from 'react';
import loadable from '@loadable/component';
const CalciumCalculator = loadable(() => import(/* webpackChunkName: "applet-calculator" */ './CalciumCalculator'));
const VitaminDCalculator = loadable(() => import(/* webpackChunkName: "applet-calculator" */ './VitaminDCalculator'));
const EnergyCalculator = loadable(() => import(/* webpackChunkName: "applet-calculator" */ './EnergyCalculator'));
const IodineCalculator = loadable(() => import(/* webpackChunkName: "applet-calculator" */ './IodineCalculator'));
const ProteinCalculator = loadable(() => import(/* webpackChunkName: "applet-calculator" */ './ProteinCalculator'));
const BMICalculator = loadable(() => import(/* webpackChunkName: "applet-calculator" */ './BMICalculator'));
const RecipeEditor = loadable(() => import(/* webpackChunkName: "applet-editor" */ './RecipeEditor'));
const ProductEditor = loadable(() => import(/* webpackChunkName: "applet-editor" */ './ProductEditor'));
const SapForm = loadable(() => import(/* webpackChunkName: "applet-other" */ './SapForm'));
const ActivityCalculator = loadable(() => import(/* webpackChunkName: "applet-calculator" */ './ActivityCalculator'));
const FoodCalculator = loadable(() => import(/* webpackChunkName: "applet-calculator" */ './FoodCalculator'));
const LactoseIntoleranceCalculator = loadable(() =>
	import(/* webpackChunkName: "applet-other" */ './LactoseIntoleranceCalculator')
);
const Feedback = loadable(() => import(/* webpackChunkName: "applet-other" */ './Feedback'));
const IFrame = loadable(() => import(/* webpackChunkName: "applet-other" */ './IFrame'));
const WeeklyMenu = loadable(() => import(/* webpackChunkName: "applet-other" */ './WeeklyMenuApplet'));
const ClearOn = loadable(() => import(/* webpackChunkName: "applet-other" */ './ClearOn'));
const ProductFeatureBrowser = loadable(() => import(/* webpackChunkName: "applet-other" */ './ProductFeatureBrowser'));
const VideoRibbonApplet = loadable(() => import(/* webpackChunkName: "applet-other" */ './VideoRibbonApplet'));
const Consulink = loadable(() => import(/* webpackChunkName: "applet-other" */ './Consulink'));
const ChatbotFrontAI = loadable(() => import(/* webpackChunkName: "applet-other" */ './ChatbotFrontAI'));
const WelcomeScreen = loadable(() => import(/* webpackChunkName: "applet-other" */ './WelcomeScreen'));
const CookieManagement = loadable(() => import(/* webpackChunkName: "applet-other" */ './CookieManagement'));
const CookieList = loadable(() => import(/* webpackChunkName: "applet-other" */ './CookieList'));
const FCProductComparison = loadable(() => import(/* webpackChunkName: "applet-other" */ './FCProductComparison'));
const ProductLocator = loadable(() => import(/* webpackChunkName: "applet-other" */ './ProductLocator'));
const BalticMailchimpNewsLetter = loadable(() =>
	import(/* webpackChunkName: "applet-other" */ './BalticMailchimpNewsLetter')
);

export const knownAppletTypes: { [type: string]: React.ComponentType<any> } = {
	CalciumCalculator,
	VitaminDCalculator,
	EnergyCalculator,
	IodineCalculator,
	ProteinCalculator,
	BMICalculator,
	RecipeEditor,
	ProductEditor,
	SapForm,
	ActivityCalculator,
	FoodCalculator,
	LactoseIntoleranceCalculator,
	Feedback,
	IFrame,
	WeeklyMenu,
	ClearOn,
	ProductFeatureBrowser,
	VideoRibbonApplet,
	Consulink,
	ChatbotFrontAI,
	WelcomeScreen,
	CookieManagement,
	CookieList,
	FCProductComparison,
	ProductLocator,
	BalticMailchimpNewsLetter,
};
