import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import MilkIcon from 'styleguide/components/Icons/AspectGroupIcons/MilkIcon/MilkIcon';
import SouredWholeMilkIcon from 'styleguide/components/Icons/AspectGroupIcons/SouredWholeMilkIcon/SouredWholeMilkIcon';
import PowderIcon from 'styleguide/components/Icons/AspectGroupIcons/PowderIcon/PowderIcon';
import ProteinIcon from 'styleguide/components/Icons/AspectGroupIcons/ProteinIcon/ProteinIcon';
import ProductsIcon from 'styleguide/components/Icons/AspectGroupIcons/ProductsIcon/ProductsIcon';
import { ContentWrapper } from '../General';
import {
	WellnessCalculatorBannersConfiguration,
	Banners,
	SupportedBanners,
} from './interfaces/WellnessCalculatorBanners';
import { WidgetCommonProps } from './interfaces/interfaces';
import { DietLightIcon, EveryDayIcon, NutritionIcon } from 'styleguide/components/Icons';

const BannersMap: Banners<SupportedBanners> = {
	IodineCalculator: {
		title: 'Jodilaskuri',
		subTitle: 'Saatko riittävästi jodia?',
		url: '/hyvinvointi/jodilaskuri/',
		icon: PowderIcon,
	},
	CalsiumCalculator: {
		title: 'Kalsiumlaskuri',
		subTitle: 'Saatko riittävästi kalsiumia?',
		url: '/hyvinvointi/kalsiumlaskuri/',
		icon: MilkIcon,
	},
	DVitaminCalculator: {
		title: 'D-vitamiinilaskuri',
		subTitle: 'Saatko riittävästi D-vitamiinia?',
		url: '/hyvinvointi/d-vitamiinilaskuri/',
		icon: SouredWholeMilkIcon,
	},
	ProteinCalculator: {
		title: 'Proteiinilaskuri',
		subTitle: 'Saatko riittävästi proteiinia?',
		url: '/hyvinvointi/proteiinilaskuri/',
		icon: ProteinIcon,
	},
	EnergyCalculator: {
		title: 'Energiantarvelaskuri',
		subTitle: 'Saatko riittävästi energiaa?',
		url: '/hyvinvointi/energiantarvelaskuri/',
		icon: ProductsIcon,
	},
	BMICalculator: {
		title: 'Painoindeksilaskuri',
		subTitle: 'Laske BMI eli painoindeksi.',
		url: '/hyvinvointi/painoindeksi-eli-bmi-laskuri/',
		icon: DietLightIcon,
	},
	ActivityCalculator: {
		title: 'Liikuntalaskuri',
		subTitle: 'Liikutko riittävästi?',
		url: '/hyvinvointi/liikuntalaskuri/',
		icon: EveryDayIcon,
	},
	FoodCalculator: {
		title: 'Ruokailutottumustesti',
		subTitle: 'Millaiset ovat ruokailutottumuksesi?',
		url: '/hyvinvointi/ruokailutottumustesti/',
		icon: NutritionIcon,
	},
};

const WellnessCalculatorBannersWrapper = styled.article`
	background: ${props => props.theme.colors.contentSectionHiglighted};
	width: 100%;
`;

const BannersContainer = styled.div`
	width: 100%;
	padding: 20px 10px;
	display: flex;
	flex-direction: column;

	${media.tablet`
  	padding: 20px 55px;
  `};

	${media.desktop`
    flex-direction: row;
		flex-wrap: wrap;
    padding: 55px;
		margin: -10px;
  `};
`;

const BannerItem = styled.a`
	background: ${props => props.theme.colors.white};
	border-radius: 3px;
	padding: 20px 30px 20px 10px;
	display: flex;
	align-items: flex-start;
	color: ${props => props.theme.colors.brandPrimary};
	text-decoration: none;
	margin: 5px 0;
	flex: 1 1 auto;

	${media.tablet`
    align-items: center;
  `};

	${media.desktop`
  	margin: 10px;
    padding: 10px 30px 10px 10px;
		flex: 1 1 auto;
		flex: 1 1 calc(50% - 20px);
		width: calc(50% - 20px)
  `};

	& > img {
		width: 60px;
		height: 60px;
		margin-right: 10px;
		flex: 0 0 60px;

		${media.tablet`
      display: block;
      margin-right: 28px;
      width: 100px;
      height: 100px;
			flex: 0 0 100px;
    `};
	}
`;

const BannerTitle = styled.h3`
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
	margin: 0;
`;

const BannerSubTitle = styled.p`
	font-size: 24px;
	font-weight: 600;
	line-height: 1.1;
	margin: 0;
	color: ${props => props.theme.colors.brandSecondary};
`;

const WellnessCalculatorBanners: React.FC<WidgetCommonProps & WellnessCalculatorBannersConfiguration> = ({
	widgetId,
	banners,
}) => {
	return (
		<WellnessCalculatorBannersWrapper id={widgetId} className="wellness-calculator-banners">
			<ContentWrapper>
				<BannersContainer>
					{banners.map((banner, index) => {
						if (!BannersMap[banner]) {
							return null;
						}

						const { title, subTitle, url, icon } = BannersMap[banner];
						const Icon = icon;

						return (
							<BannerItem key={`${url}-${index}`} href={url}>
								<Icon />
								<div>
									<BannerTitle>{title}</BannerTitle>
									<BannerSubTitle>{subTitle}</BannerSubTitle>
								</div>
							</BannerItem>
						);
					})}
				</BannersContainer>
			</ContentWrapper>
		</WellnessCalculatorBannersWrapper>
	);
};

export default WellnessCalculatorBanners;
