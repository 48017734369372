import * as React from 'react';
import { AppletConfiguration } from './interfaces/Applet';
import { WidgetCommonProps } from './interfaces/interfaces';
import { AppletCommonProps } from '../Applets/types';
import { knownAppletTypes } from '../Applets';

export interface AppletRendererProps {
	id: string;
	appletName: string;
	parameters: string;
}

const AppletRenderer: React.FC<AppletRendererProps> = ({ id, appletName, parameters }) => {
	const appletElement = knownAppletTypes[appletName];
	if (appletElement) {
		const props: AppletCommonProps = { id, parameters };
		return React.createElement(appletElement, props);
	}
	return null;
};

const Applet: React.FC<WidgetCommonProps & AppletConfiguration> = ({ widgetId, appletName, parameters }) => {
	return <AppletRenderer id={widgetId} appletName={appletName} parameters={parameters} />;
};

export default Applet;
